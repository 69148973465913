<template>
  <div>
    <v-navigation-drawer
      v-model="showDrawer"
      fixed
      floating
      temporary
      width="300"
    >
      <AppMenu v-if="showDrawer" />
    </v-navigation-drawer>
    <v-app-bar
      app
      collapse
      color="secondary"
      dark
      dense
      flat
    >
      <v-app-bar-nav-icon @click.stop="showDrawer = !showDrawer" />
      <buscador-global
        @msg="$emit('msg',$event)"
        @httpError="$emit('httpError',$event)"
      />
    </v-app-bar>
  </div>
</template>
<script>
import AppMenu from "@/components/appbar/AppMenu.vue";
import BuscadorGlobal from "@/components/buscador/BuscadorGlobal.vue";
export default {
  name: "AppBar",
  components: { AppMenu, BuscadorGlobal },
  data() {
    return {
      showDrawer: false,
    };
  },
};
</script>