<template>
  <div>
    <!-- <v-card> -->
    <!-- <v-card-title> {{ notificacion.title }} </v-card-title> -->
    <!-- {{ $session.getAll() }} -->
    <!-- </v-card> -->
  </div>
</template>
<script>
//notificaciones al personal sobre sus dias de vacaciones
export default {
  name: "NotificacionesPersonal",
  methods: {
    async _checkDiasVacaciones() {
      this.$http.get("personal/diasvacaciones");
    },
  },
  mounted() {},
  watch: {
    $route(a, b) {
      // console.log(a.name);
    },
  },
};
</script>