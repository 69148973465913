const version = '2.0524.1221';

import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';

import VueResource from 'vue-resource';
import VueMask from 'v-mask';
import VueSession from 'vue-session';
import VuePageTransition from 'vue-page-transition';
import exportFromJSON from 'export-from-json';
import VueHtmlToPaper from 'vue-html-to-paper';

//importamos amplify para cognito
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

Vue.use(VueHtmlToPaper, {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: ['./styles/print.css']
});
Vue.use(VueSession, { persist: true });
Vue.use(VueResource);
Vue.use(VueMask);
Vue.use(VuePageTransition);

// const api = 'http://localhost/crmcuu/crmcuu3-admin-api/';
const api = 'https://api.cruzrojachihuahua.org/administracion/';

Vue.prototype.$newApp = 'https://app.cruzrojachihuahua.org/';

// Vue.prototype.$apiAdmin = 'http://localhost/crmcuu/crmcuu3-admin-api/';
Vue.prototype.$apiAdmin = "https://api.cruzrojachihuahua.org/admin/";

Vue.http.options.root = api;
Vue.prototype.$api = api;
Vue.prototype.$files = 'http://archivos.cruzrojachihuahua.org/';

Vue.http.options.emulateJSON = true;
Vue.config.productionTip = false;

Vue.prototype.$primary = '#121417';
Vue.prototype.$secondary = '#B40A1B';
Vue.prototype.$accent = '#BC0022';
Vue.prototype.$error = '#F85C50';
Vue.prototype.$background = '#FEFFFE';

Vue.prototype.$headers = {
  headers: {
    Token: null,
    'Cache-Control': 'no-cache'
  }
};

const $llenarCampo = 'Este campo es obligatorio';
Vue.prototype.$llenarCampo = $llenarCampo;
Vue.prototype.$lleneCampos = 'Llena los campos indicados';
Vue.prototype.$noInternet =
  'Sucedió un error al comunicarnos con el servidor. Verifica tu conexión a internet';
Vue.prototype.$noData = 'No hay datos disponibles';
Vue.prototype.$nF = n => {
  return Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN'
  }).format(n);
};

//para exportar a csv
Vue.prototype.$doCSV = (data, fileName) => {
  const exportType = 'xls';
  exportFromJSON({
    data,
    fileName,
    exportType
  });
};

Vue.prototype.$EventBus = new Vue();

Vue.prototype.$version = version;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
