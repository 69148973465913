<template>
  <v-list-item
    color="white"
    link
    :to="to"
  >
    <v-list-item-icon v-if="icon">
      <v-icon v-text="icon" />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-text="title" />
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: "ListItemMenu",
  props: {
    color: String,
    dense: Boolean,
    icon: String,
    title: String,
    to: String,
  },
};
</script>