export default {
    data() {
        return {
            tipo: null,
            coordinador: false,
        }
    },
    created() {
        this.tipo = this.$session.get("tipo");
        this.coordinador = this.$session.get("coordinacion");
    },
    methods: {
        _mustShow(permisos = "") {
            try {
                if (permisos === "todos") return true;
                if (
                    this.coordinador &&
                    (permisos === "coordinador" || permisos.search("coordinador") >= 0)
                ) return true
                return permisos.split(/[,]/).find((e) => e === this.tipo);
            } catch (err) {
                return false;
            }
        },
    }
}