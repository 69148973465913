<template>
  <v-list
    nav
    dense
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title
          class="title"
          style="padding:8px 0"
          v-text="'Cruz Roja Mexicana'"
        />
      </v-list-item-content>
    </v-list-item>
    <v-list-item-group
      active-class="accent"
      color="#fefffe"
    >
      <div v-if="tipo && tipo != 'direccion'">
        <div
          v-for="(item, idx) in menu"
          :key="idx"
        >
          <div
            v-if="_mustShow(item.permitido)"
            class="mb4"
          >
            <ListItemMenu
              v-if="item.component == 'item'"
              :icon="item.icon"
              :title="item.title"
              :to="item.to"
            />
            <ListGroupMenu
              v-if="item.component == 'group'"
              :icon="item.icon"
              :title="item.title"
              :items="item.items"
            />
          </div>
        </div>
      </div>
      <div v-if="isCoordinador">
        <v-divider class="mt24 mb24" />
        <ListItemMenu
          class="mt4"
          icon="event_available"
          title="Vacaciones por autorizar"
          to="/personal/vacaciones-pendientes"
        />
        <ListItemMenu
          class="mt4"
          icon="date_range"
          title="Vacaciones pendientes"
          to="/vacaciones-pendientes"
        />
        <ListGroupMenu
          class="mt4"
          icon="storefront"
          title="Ordenes de compra"
          :items="[{ text: 'Nueva orden de compra', to: '/compra', permitido: 'todos', }, { text: 'Ordenes de compras', to: '/compras', permitido: 'todos', }]"
        />
      </div>
      <v-divider class="mt24 mb24" />
      <ListItemMenu
        @click="this.$session.destroy()"
        icon="face"
        title="Mi perfil"
        :to='"/persona/" + $session.get("persona") + "/perfil"'
      />
      <ListItemMenu
        class="mt4"
        icon="settings"
        title="Cambiar contraseña"
        to="/configuracion/contrasena"
      />
      <ListItemMenu
        @click="this.$session.destroy()"
        icon="exit_to_app"
        title="Cerrar sesión"
        to="/login"
      />
      <v-divider class="mt24 mb24" />
      <v-list-item
        dense
        disabled
      >
        <v-list-item-content>
          <v-list-item-title v-text="$version" />
          <v-list-item-subtitle v-html="'<br>'+ip" />
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
import ListItemMenu from "@/components/lists/ListItemMenu";
import ListGroupMenu from "@/components/lists/ListGroupMenu";
import permisos from "@/mixins/permisos.js";
export default {
  name: "AppMenu",
  mixins: [permisos],
  components: { ListItemMenu, ListGroupMenu },
  data() {
    return {
      ip: null,
      isCoordinador: false,
      menu: [
        {
          component: "item",
          icon: "home",
          title: "Inicio",
          to: "/",
          permitido: "todos",
        },
        {
          component: "group",
          icon: "local_shipping",
          title: "Ambulancias",
          //'administrativo','auxiliar administrativo','captacion de fondos','direccion','enfermero','medico','medico pasante','paramedico','otro','radio operador','paramedico voluntario'
          permitido: "administrativo,auxiliar administrativo",
          items: [
            {
              text: "Calendario",
              to: "/ambulancias/calendario",
              permitido: "todos",
            },
            {
              text: "Registrar evento",
              to: "/ambulancias/registrar/serviciosespeciales",
              permitido: "todos",
            },
            { text: "FRAPS", to: "/fraps", permitido: "todos" },
            {
              text: "Configuración ambulancias",
              to: "/ambulancias/configuracion/general",
              permitido: "administrativo",
            },
          ],
        },
        {
          //if administrativo
          component: "item",
          icon: "notifications",
          title: "Avisos",
          to: "/avisos",
          permitido: "administrativo",
        },
        //if administrativo
        {
          component: "group",
          icon: "local_hospital",
          title: "Bases médicas",
          permitido: "administrativo",
          items: [
            {
              text: "Bases medicas registradas",
              to: "/basesmedicas",
              permitido: "todos",
            },
            {
              text: "Registrar base medica",
              to: "/basemedica",
              permitido: "todos",
            },
            { text: "Turnos", to: "/turnos", permitido: "todos" },
          ],
        },
        // {
        //   component: "item",
        //   icon: "shopping bag",
        //   title: "Cepos",
        //   to: "/cepos",
        //   permitido: "administrativo,auxiliar administrativo",
        // },
        {
          component: "group",
          icon: "school",
          title: "Capacitación",
          permitido: "administrativo,auxiliar administrativo,capacitacion",
          items: [
            {
              text: "Cursos registrados",
              to: "/cursos",
              permitido: "administrativo,auxiliar administrativo,capacitacion",
            },
            {
              text: "Alumnos registrados",
              to: "/alumnos",
              permitido: "administrativo,auxiliar administrativo,capacitacion",
            },
            {
              text: "Buscar referencia de pago",
              to: "/cursos/referencias",
              permitido: "administrativo,auxiliar administrativo,capacitacion",
            },
          ],
        },
        {
          component: "item",
          icon: "event",
          title: "Citas médicas",
          to: "/citas",
          permitido: "administrativo,auxiliar administrativo",
        },
        {
          component: "group",
          icon: "apartments",
          title: "Donadores",
          permitido: "todos",
          items: [
            {
              text: "Donadores registrados",
              to: "/donadores",
              permitido: "todos",
            },
            {
              text: "Registrar donador",
              to: "/donador",
              permitido: "todos",
            },
            {
              text: "Donativos registrados",
              to: "/donativos",
              permitido: "todos",
            },
            { text: "Registrar donativo", to: "/donativo", permitido: "todos" },
            {
              text: "Programas",
              to: "/programas",
              permitido: "administrativo",
            },
          ],
        },
        {
          component: "group",
          icon: "description",
          title: "Facturación",
          items: [
            {
              text: "Solicitar factura",
              to: "/facturar",
              permitido: "administrativo,auxiliar administrativo",
            },
            {
              text: "Facturas pendientes de enviar",
              to: "/facturacion/pendiente",
              permitido: "administrativo,auxiliar administrativo",
            },
            {
              text: "Facturas enviadas",
              to: "/facturacion/enviada",
              permitido: "administrativo,auxiliar administrativo",
            },
            {
              text: "Generar depoósitos",
              to: "/reportes/generardepositos",
              permitido: "administrativo,auxiliar administrativo",
            },
          ],
          permitido: "administrativo,auxiliar administrativo",
        },
        {
          component: "group",
          icon: "storefront",
          title: "Proveedores",
          items: [
            {
              text: "Nueva orden de compra",
              to: "/compra",
              permitido: "administrativo,compras,coordinador",
            },
            {
              text: "Ordenes de compras",
              to: "/compras",
              permitido: "administrativo,auxiliar administrativo,compras",
            },
            // {
            //   text: "Materiales registrados",
            //   to: "/materiales",
            //   permitido: "administrativo,auxiliar administrativo,compras",
            // },
            {
              text: "Proveedores",
              to: "/proveedores",
              permitido: "administrativo,auxiliar administrativo,compras",
            },
            {
              text: "Tipos de materiales",
              to: "/tiposdemateriales",
              permitido: "administrativo,auxiliar administrativo,compras",
            },
          ],
          permitido: "administrativo,auxiliar administrativo,compras",
        },
        {
          component: "group",
          icon: "supervisor_account",
          title: "Pacientes",
          permitido: "administrativo,auxiliar administrativo",
          items: [
            {
              text: "Buscar paciente",
              to: "/pacientes",
              permitido: "administrativo,auxiliar administrativo",
            },
            {
              text: "Registrar paciente",
              to: "/paciente",
              permitido: "administrativo,auxiliar administrativo",
            },
          ],
          permitido: "administrativo,auxiliar administrativo",
        },
        {
          component: "group",
          icon: "groups",
          title: "Personal",
          permitido: "administrativo,auxiliar administrativo",
          items: [
            {
              text: "Personal registrado",
              to: "/personal",
              permitido: "administrativo,auxiliar administrativo",
            },
            {
              text: "Registrar personal",
              to: "/personas",
              permitido: "administrativo,auxiliar administrativo",
            },
            {
              text: "Configuración",
              to: "/personal/configuracion/documentacion",
              permitido: "administrativo",
            },
            {
              text: "Horarios",
              to: "/personal/horarios",
              permitido: "administrativo, coordinador",
            },
            {
              text: "Vacaciones por autorizar",
              to: "/personal/vacaciones-pendientes",
              permitido: "administrativo, coordinador",
            },
          ],
          permitido: "administrativo,auxiliar administrativo",
        },
        {
          component: "group",
          icon: "insights",
          title: "Reportes",
          permitido: "administrativo,auxiliar administrativo,capacitacion",
          items: [
            {
              text: "Corte de caja",
              to: "/reportes/cortedecaja",
              permitido: "administrativo,auxiliar administrativo",
            },
            {
              text: "Cuentas pendientes",
              to: "/reportes/cuentaspendientes",
              permitido: "administrativo,auxiliar administrativo",
            },
            {
              text: "Depositos",
              to: "/reportes/depositos",
              permitido: "administrativo,auxiliar administrativo",
            },
            {
              text: "Diario de servicios otorgados",
              to: "/reportes/serviciosotorgados/diario",
              permitido: "administrativo",
            },
            {
              text: "Estado de cuenta de alumnos",
              to: "/reportes/cursos",
              permitido: "administrativo,auxiliar administrativo,capacitacion",
            },
            {
              text: "Mensual de servicios otorgados",
              to: "/reportes/serviciosotorgados/mensual",
              permitido: "administrativo",
            },
            {
              text: "Servicios referenciados",
              to: "/reportes/serviciosreferenciados",
              permitido: "administrativo",
            },
            {
              text: "Servicios de ambulancias",
              to: "/reportes/serviciosambulancias",
              permitido: "administrativo,auxiliar administrativo",
            },
            {
              text: "Vacaciones",
              to: "/reportes/vacaciones/calendario",
              permitido: "administrativo,auxiliar administrativo",
            },
            {
              text: "Vacaciones pendientes",
              to: "/reportes/vacacionespendientes",
              permitido: "administrativo,auxiliar administrativo",
            },
          ],
        },
        {
          component: "group",
          icon: "loyalty",
          title: "Servicios",
          permitido: "administrativo",
          items: [
            {
              text: "Servicios registrados",
              to: "/servicios",
              permitido: "administrativo",
            },
            {
              text: "Registrar servicio",
              to: "/servicio",
              permitido: "administrativo",
            },
            {
              text: "Cuentas contables",
              to: "/cuentascontables",
              permitido: "administrativo",
            },
            {
              text: "Cuentas peoplesoft",
              to: "/cuentaspeoplesoft",
              permitido: "administrativo",
            },
            {
              text: "Tipos de ingreso",
              to: "/tiposingreso",
              permitido: "administrativo",
            },
          ],
        },
        //{component:"group",icon:"language",title:"Sitio web",items:[{text:"Publicaciones realizadas",to:"/posts"},{text:"Crear publicación",to:"/post"},],},
        {
          component: "item",
          icon: "account_circle",
          title: "Usuarios",
          to: "/usuarios",
          permitido: "administrativo",
        },
        {
          component: "item",
          icon: "print",
          title: "Reimprimir ticket",
          to: "/reimprimir-ticket",
          permitido: "administrativo,auxiliar administrativo",
        },
      ],
    };
  },
  methods: {
    getIP() {
      fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then(({ ip }) => {
          this.ip = ip;
        });
    }
  },
  created() {
    const c = this.$session.get("coordinacion");
    this.isCoordinador = c && c != "" ? true : false;
    this.getIP()
  }
};
</script>
