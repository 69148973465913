import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/alumnos",
    name: "CursosAlumnosListado",
    component: () => import("@/views/cursos/CursosAlumnosListado.vue"),
  },
  {
    path: "/ambulancias/registrar",
    name: "AmbulanciasConfiguracionRegistro",
    component: () => import("@/views/ambulancias/AmbulanciasRegistrar.vue"),
    children: [
      {
        path: "serviciosespeciales",
        component: () =>
          import(
            "@/views/ambulancias/registrar/AmbulanciasRegistrarServiciosEspeciales.vue"
          ),
      },
      {
        path: "trasladoslocales",
        component: () =>
          import(
            "@/views/ambulancias/registrar/AmbulanciasRegistrarTrasladosLocales.vue"
          ),
      },
      {
        path: "trasladosforaneos",
        component: () =>
          import(
            "@/views/ambulancias/registrar/AmbulanciasRegistrarTrasladosForaneos.vue"
          ),
      },
    ],
  },
  {
    path: "/ambulancias/calendario",
    name: "AmbulanciasCalendario",
    component: () => import("@/views/ambulancias/AmbulanciasCalendario.vue"),
  },
  {
    path: "/ambulancias/configuracion",
    name: "AmbulanciasConfiguracion",
    component: () => import("@/views/ambulancias/AmbulanciasConfiguracion.vue"),
    children: [
      {
        path: "general",
        component: () =>
          import(
            "@/views/ambulancias/configuracion/AmbulanciasConfiguracionGeneral.vue"
          ),
      },
      {
        path: "serviciosespeciales",
        component: () =>
          import(
            "@/views/ambulancias/configuracion/AmbulanciasConfiguracionServiciosEspeciales.vue"
          ),
      },
      {
        path: "trasladoslocales",
        component: () =>
          import(
            "@/views/ambulancias/configuracion/AmbulanciasConfiguracionTrasladosLocales.vue"
          ),
      },
      {
        path: "trasladosforaneos",
        component: () =>
          import(
            "@/views/ambulancias/configuracion/AmbulanciasConfiguracionTrasladosForaneos.vue"
          ),
      },
      {
        path: "fraps",
        component: () =>
          import(
            "@/views/ambulancias/configuracion/AmbulanciasConfiguracionFraps.vue"
          ),
      },
    ],
  },
  {
    path: "/avisos",
    name: "AvisosForm",
    component: () => import("@/views/avisos/AvisosForm.vue"),
  },
  {
    path: "/basesmedicas",
    name: "ClinicasListado",
    component: () => import("@/views/clinicas/ClinicasListado.vue"),
  },
  {
    path: "/basemedica/:idCli?",
    name: "ClinicasForm",
    component: () => import("@/views/clinicas/ClinicasForm.vue"),
  },
  {
    path: "/citas",
    name: "CitasCalendario",
    component: () => import("@/views/citas/CitasCalendario.vue"),
  },
  {
    path: "/compra",
    name: "ComprasForm",
    component: () => import("@/views/compras/ComprasForm.vue"),
  },
  {
    path: "/compras",
    name: "ComprasListado",
    component: () => import("@/views/compras/ComprasListado.vue"),
  },
  {
    path: "/coordinaciones",
    name: "Coordinacioneslistado",
    component: () => import("@/views/coordinaciones/CoordinacionesListado.vue"),
  },
  {
    path: "/cursos",
    name: "CursosListado",
    component: () => import("@/views/cursos/CursosListado.vue"),
  },
  {
    path: "/curso/:idCur",
    name: "CursosDetalle",
    component: () => import("@/views/cursos/CursosDetalle.vue"),
    children: [
      {
        path: "configuracion",
        component: () =>
          import("@/views/cursos/detalle/CursosDetalleConfiguracion.vue"),
      },
      {
        path: "alumnos",
        component: () =>
          import("@/views/cursos/detalle/CursosDetalleAlumnos.vue"),
      },
      // { path: 'documentacion', component: () => import('@/views/cursos/detalle/CursosDetalleDocumentacion.vue'), },
    ],
  },
  {
    path: "/cursos/alumnos/pagos/:idCur/:idPac/:idCpa",
    name: "CursosPacientesPagos",
    component: () => import("@/views/cursos/CursosPacientesPagos.vue"),
  },
  {
    path: "/cursos/referencias",
    name: "CursosReferencias",
    component: () => import("@/views/cursos/CursosReferencias.vue"),
  },
  {
    path: "/cuentascontables",
    name: "CuentasContablesListado",
    component: () =>
      import("@/views/cuentascontables/CuentasContablesListado.vue"),
  },
  {
    path: "/cuentaspeoplesoft",
    name: "CuentasPeoplesoftListado",
    component: () =>
      import("@/views/cuentascontables/CuentasPeoplesoftListado.vue"),
  },
  {
    path: "/documentacion",
    name: "DocumentacionListado",
    component: () => import("@/views/documentacion/DocumentacionListado.vue"),
  },
  {
    path: "/diasferiados",
    name: "DiasFeriadosListado",
    component: () => import("@/views/diasferiados/DiasFeriadosListado.vue"),
  },
  {
    path: "/donadores",
    name: "DonadoresListado",
    component: () => import("@/views/donadores/DonadoresListado.vue"),
  },
  {
    path: "/donador",
    name: "DonadoresForm",
    component: () => import("@/views/donadores/DonadoresForm.vue"),
  },
  {
    path: "/donador/:idDon/",
    name: "DonadoresDetalle",
    component: () => import("@/views/donadores/DonadoresDetalle.vue"),
    children: [
      {
        path: "info",
        component: () => import("@/views/donadores/DonadoresForm.vue"),
      },
      {
        path: "ambulancias",
        component: () =>
          import("@/views/donadores/detalle/DonadoresDetalleAmbulancias.vue"),
      },
      {
        path: "donativos",
        component: () =>
          import("@/components/donadores/DonadoresDonativos.vue"),
      },
      {
        path: "facturas",
        component: () =>
          import("@/views/donadores/detalle/DonadoresDetalleFacturas.vue"),
      },
      {
        path: "fraps",
        component: () =>
          import("@/views/donadores/detalle/DonadoresDetalleFraps.vue"),
      },
      {
        path: "programas",
        component: () =>
          import("@/views/donadores/detalle/DonadoresDetalleProgramas.vue"),
      },
      {
        path: "seguimiento",
        component: () =>
          import("@/views/donadores/detalle/DonadoresDetalleSeguimiento.vue"),
      },
      // { path: 'servicios', component: () => import('@/views/donadores/detalle/PacientesDetalleServicios.vue'), },{ path: 'cursos', component: () => import('@/views/donadores/detalle/PacientesDetalleCursos.vue'), },
    ],
  },
  {
    path: "/donativos",
    name: "DonativosListado",
    component: () => import("@/views/donativos/DonativosListado.vue"),
  },
  {
    path: "/donativo",
    name: "DonativosForm",
    component: () => import("@/views/donativos/DonativosForm.vue"),
  },
  {
    path: "/facturar",
    name: "FacturacionForm",
    component: () => import("@/views/facturacion/FacturacionForm.vue"),
  },
  {
    path: "/facturacion/:estatus?",
    name: "FacturacionListado",
    component: () => import("@/views/facturacion/FacturacionListado.vue"),
  },
  {
    path: "/fraps",
    name: "FrapsListado",
    component: () => import("@/views/fraps/FrapsListado.vue"),
  },
  {
    path: "/materiales",
    name: "MaterialesListado",
    component: () => import("@/views/materiales/MaterialesListado.vue"),
  },
  {
    path: "/tiposdemateriales",
    name: "MaterialesTiposListado",
    component: () => import("@/views/materiales/MaterialesTiposListado.vue"),
  },
  // { path: '/material/bases', name: 'MaterialesBasesSeleccionar', component: () => import('@/views/materiales/MaterialesBasesSeleccionar.vue') }, { path: '/material/base/:idCli', name: 'MaterialesBaseDetalle', component: () => import('@/views/materiales/MaterialesBaseDetalle.vue'), },
  {
    path: "/pacientes",
    name: "PacientesBuscar",
    component: () => import("@/views/pacientes/PacientesBuscar.vue"),
  },
  {
    path: "/paciente/:idPac?",
    name: "PacientesForm",
    component: () => import("@/views/pacientes/PacientesForm.vue"),
  },
  {
    path: "/paciente/detalle/:idPac",
    name: "PacientesDetalle",
    component: () => import("@/views/pacientes/PacientesDetalle.vue"),
    children: [
      {
        path: "historiaclinica",
        component: () =>
          import("@/views/pacientes/detalle/PacientesDetalleHistoria.vue"),
      },
      {
        path: "servicios",
        component: () =>
          import("@/views/pacientes/detalle/PacientesDetalleServicios.vue"),
      },
      {
        path: "cursos",
        component: () =>
          import("@/views/pacientes/detalle/PacientesDetalleCursos.vue"),
      },
    ],
  },
  {
    path: "/personal",
    name: "PersonalListado",
    component: () => import("@/views/personal/PersonalListado.vue"),
  },
  {
    path: "/personal/configuracion/",
    name: "PersonalConfiguracion",
    component: () => import("@/views/personal/PersonalConfiguracion.vue"),
    children: [
      {
        path: "coordinaciones",
        name: "CoordinacioneslistadoConfig",
        component: () =>
          import("@/views/coordinaciones/CoordinacionesListado.vue"),
      },
      {
        path: "documentos",
        name: "DocumentacionListadoConfig",
        component: () =>
          import("@/views/documentacion/DocumentacionListado.vue"),
      },
      {
        path: "documentacion",
        component: () =>
          import(
            "@/views/personal/configuracion/PersonalConfiguracionDocumentacion.vue"
          ),
      },
      {
        path: "diasferiados",
        component: () => import("@/views/diasferiados/DiasFeriadosListado.vue"),
      },
      {
        path: "turnos",
        component: () => import("@/views/turnos/TurnosListado.vue"),
      },
      ,
      {
        path: "puestos",
        name: "PuestosListado",
        component: () => import("@/views/puestos/PuestosListado.vue"),
      },
    ],
  },
  {
    path: "/personas",
    name: "PersonalForm",
    component: () => import("@/views/personal/PersonalForm.vue"),
  },
  {
    path: "/personas-redirect",
    redirect: "/personas",
  },
  {
    path: "/persona/:id",
    name: "PersonalDetalle",
    component: () => import("@/views/personal/PersonalDetalle.vue"),
    props: true,
    children: [
      {
        path: "cursos",
        component: () =>
          import("@/views/pacientes/detalle/PacientesDetalleCursos.vue"),
        props: true,
      },
      {
        path: "documentacion",
        component: () =>
          import("@/views/personal/detalle/PersonalDetalleDocumentacion.vue"),
        props: true,
      },
      {
        path: "historial",
        component: () =>
          import("@/views/personal/detalle/PersonalDetalleHistorial.vue"),
        props: true,
      },
      {
        path: "historiaclinica",
        component: () =>
          import("@/views/pacientes/detalle/PacientesDetalleHistoria.vue"),
        props: true,
      },
      {
        path: "serviciosotorgados",
        component: () =>
          import("@/views/pacientes/detalle/PacientesDetalleServicios.vue"),
        props: true,
      },
      {
        path: "vacaciones",
        component: () =>
          import("@/views/personal/detalle/PersonalDetalleVacaciones.vue"),
        props: true,
      },
      {
        path: "perfil",
        component: () =>
          import("@/views/personal/detalle/PersonalDetallePaciente.vue"),
        props: true,
      },
      {
        path: "recibosnomina",
        component: () =>
          import("@/views/personal/detalle/PersonalDetalleRecibosNomina.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/personal/vacaciones-pendientes",
    name: "PersonalVacacionesPendientes",
    component: () =>
      import("@/views/personal/PersonalVacacionesPendientes.vue"),
  },
  {
    path: "/personal/horarios",
    name: "PersonalHorarios",
    component: () => import("@/views/personal/PersonalHorarios.vue"),
  },
  {
    path: "/posts",
    name: "PostsListado",
    component: () => import("@/views/posts/PostsListado.vue"),
  },
  {
    path: "/post/:idPos?",
    name: "PostsForm",
    component: () => import("@/views/posts/PostsForm.vue"),
  },
  {
    path: "/proveedores",
    name: "ProveedoresListado",
    component: () => import("@/views/proveedores/ProveedoresListado.vue"),
  },
  {
    path: "/proveedor",
    name: "ProveedoresForm",
    component: () => import("@/views/proveedores/ProveedoresForm.vue"),
  },
  {
    path: "/proveedor/:idPro/",
    name: "ProveedoresDetalle",
    component: () => import("@/views/proveedores/ProveedoresDetalle.vue"),
    children: [
      {
        path: "info",
        component: () => import("@/views/proveedores/ProveedoresForm.vue"),
      },
      {
        path: "contacto",
        component: () => import("@/views/proveedores/ProveedoresContacto.vue"),
      },
      {
        path: "ordenesdecompra",
        component: () =>
          import("@/views/proveedores/ProveedoresOrdenesCompra.vue"),
      },
    ],
  },
  {
    path: "/programas",
    name: "ProgramasListado",
    component: () => import("@/views/programas/ProgramasListado.vue"),
  },
  {
    path: "/programa/:idPrg",
    name: "ProgramasDetalle",
    component: () => import("@/views/programas/ProgramasDetalle.vue"),
    children: [
      {
        path: "servicios",
        component: () =>
          import("@/views/programas/ProgramasDetalleServicios.vue"),
      },
      {
        path: "donadores",
        component: () =>
          import("@/views/programas/ProgramasDetalleDonadores.vue"),
      },
      {
        path: "beneficiarios",
        component: () =>
          import("@/views/programas/ProgramasDetalleBeneficiarios.vue"),
      },
    ],
  },
  {
    path: "/puesto/:idPue?",
    name: "PuestosForm",
    component: () => import("@/views/puestos/PuestosForm.vue"),
  },
  {
    path: "/servicios",
    name: "ServiciosListado",
    component: () => import("@/views/servicios/ServiciosListado.vue"),
  },
  {
    path: "/reportes/cortedecaja/:idCli?",
    name: "ReportesCorteDeCaja",
    component: () => import("@/views/reportes/ReportesCorteDeCaja.vue"),
  },
  {
    path: "/reportes/cursos",
    name: "ReportesCursosPagos",
    component: () => import("@/views/reportes/ReportesCursosPagos.vue"),
  },
  {
    path: "/reportes/cuentaspendientes",
    name: "ReportesCuentasPendientes",
    component: () => import("@/views/reportes/ReportesCuentasPendientes.vue"),
  },
  {
    path: "/reportes/depositos",
    name: "ReportesDepositos",
    component: () => import("@/views/reportes/ReportesDepositos.vue"),
  },
  {
    path: "/reportes/generardepositos",
    name: "ReportesDepositosGenerar",
    component: () => import("@/views/reportes/ReportesDepositosGenerar.vue"),
  },
  {
    path: "/reportes/serviciosambulancias",
    name: "ReporteServiciosAmbulancias",
    component: () => import("@/views/reportes/ReporteServiciosAmbulancias.vue"),
  },
  {
    path: "/reportes/serviciosotorgados/diario",
    name: "ReportesServiciosOtorgadosDiario",
    component: () =>
      import("@/views/reportes/ReportesServiciosOtorgadosDiario.vue"),
  },
  {
    path: "/reportes/serviciosotorgados/mensual/:idCli?/:fecha?",
    name: "ReportesServiciosOtorgadosMensual",
    component: () =>
      import("@/views/reportes/ReportesServiciosOtorgadosMensual.vue"),
  },
  {
    path: "/reportes/serviciosreferenciados",
    name: "ReportesServiciosReferenciados",
    component: () =>
      import("@/views/reportes/ReportesServiciosReferenciados.vue"),
  },
  {
    path: "/reportes/vacaciones/:tipo?",
    name: "ReportesVacaciones",
    component: () => import("@/views/reportes/ReportesVacaciones.vue"),
  },
  {
    path: "/reportes/vacacionespendientes",
    name: "ReportesVacacionesPendientes",
    component: () =>
      import("@/views/reportes/ReportesVacacionesPendientes.vue"),
  },
  {
    path: "/servicio/:idSer?",
    name: "ServiciosForm",
    component: () => import("@/views/servicios/ServiciosForm.vue"),
  },
  {
    path: "/tiposingreso",
    name: "TiposIngresoListado",
    component: () => import("@/views/tiposingreso/TiposIngresoListado.vue"),
  },
  {
    path: "/usuarios",
    name: "UsuariosListado",
    component: () => import("@/views/usuarios/UsuariosListado.vue"),
  },
  {
    path: "/turnos",
    name: "TurnosListado",
    component: () => import("@/views/turnos/TurnosListado.vue"),
  },
  {
    path: "/reimprimir-ticket",
    name: "TicketReimprimir",
    component: () => import("@/views/imprimibles/TicketReimprimir.vue"),
  },
  {
    path: "/ticket/:tipo/:idCgo",
    name: "TicketPrint",
    component: () => import("@/views/imprimibles/TicketPrint.vue"),
  },
  {
    path: "/configuracion/contrasena",
    name: "SesionCambiarContrasena",
    component: () => import("@/views/sesion/SesionCambiarContrasena.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
