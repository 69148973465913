<template>
  <div class="BuscadorGlobal">
    <v-btn
      icon
      @click="showDialog=true"
    >
      <v-icon v-text="'search'" />
    </v-btn>
    <v-dialog
      v-model="showDialog"
      width="800"
    >
      <v-card>
        <v-select
          filled
          hide-details
          :items="tipoBusqueda"
        ></v-select>
        <v-text-field
          label="Solo"
          filled
          hide-details
        />
      </v-card>
      <!-- <v-card>
        <v-card-title>
          Buscador
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="showDialog=false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>

          Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut voluptates quas quibusdam et corrupti aperiam commodi ab officia eos, adipisci itaque ut repellat, ipsa animi tempora quae ipsam quaerat magnam.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDialog=false"
          >Cerrar</v-btn>
        </v-card-actions>
      </v-card> -->
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "BuscadorGlobal",
  data() {
    return {
      showDialog: false,
      tipoBusqueda: [
        { text: "Cursos", value: "cursos" },
        { text: "Donadores", value: "donadores" },
        { text: "Eventos", value: "eventos" },
        { text: "Facturas", value: "facturas" },
        { text: "Pacientes", value: "pacientes" },
        { text: "Personal", value: "personal" },
        { text: "Todo", value: "todo" },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.v-text-field.v-text-field--solo .v-input__prepend-outer,
.v-text-field.v-text-field--solo .v-input__append-outer {
  margin-top: -4px !important;
}
</style>