import { render, staticRenderFns } from "./BuscadorGlobal.vue?vue&type=template&id=666fdce9&scoped=true"
import script from "./BuscadorGlobal.vue?vue&type=script&lang=js"
export * from "./BuscadorGlobal.vue?vue&type=script&lang=js"
import style0 from "./BuscadorGlobal.vue?vue&type=style&index=0&id=666fdce9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "666fdce9",
  null
  
)

export default component.exports